import { defineComponent, ref, Ref } from '@vue/composition-api';

import pngTool from './png-tool';

export default defineComponent({
	setup() {
		const dataTool: Ref<any> = ref([
			{
				onlyid: 'code',
				title: '二维码生成器',
				img: '/upload/image/4bb4_3f739430e128ca9fa152b596225bd20cc72db89a.jpg?nm=/static/testimg/b1.jpg',
				author: 'sheaneh',
				description: '在线生成二维码',
			},
			{
				onlyid: 'js-encrypt',
				title: 'JS混淆加密',
				img: '/upload/image/4bb4_e341330ea06d2681eb7418dc254a33441757a6cb.jpg',
				author: 'sheaneh',
				description: '提供javascript代码的混淆和加密',
			},
			{
				onlyid: 'html-escape',
				title: 'HTML转义',
				img: '/upload/image/4bb4_67b71611162df199d9ff0a760de7699cbf2f394b.png?nm=/upload/image/4bb4_e897336718a3063198322817699285ec3c7b30dd.jpg',
				author: 'sheaneh',
				description: 'HTML特殊字符的转换与显示',
			},
			{
				onlyid: 'timestamp',
				title: 'Unix时间戳转换',
				img: '/upload/image/4bb4_124f37581ee5ee90a4fb59b3648a3d36b6ceb632.png',
				author: 'sheaneh',
				description: '当心32位操作系统下的2038问题',
			},
			{
				onlyid: 'img-to-base64',
				title: '图片转Base64',
				img: '/upload/image/4bb4_667798336d629267f1381fa863297d2ad7b2faeb.png',
				author: 'sheaneh',
				description: '图片与base64编码相互转换',
			},
		]);
		return { dataTool, pngTool };
	},
});
//(() => {let a = setInterval(() => {}, 1000);for (let i = 0; i <= a; i++) {clearInterval(i);}})();
